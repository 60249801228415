<template>
  <div class="admin-login">
    <AdminHeader />
    <main>
      <b-container class="mt-5">
        <b-row>
          <b-col>
            <b-alert :show="!!message" variant="danger">{{ message }}</b-alert>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <b-form @submit.prevent="handleSubmit(onLogin)">
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Username:" label-for="input-username">
                      <validation-provider
                        name="Username"
                        rules="required|email"
                        v-slot="validationContext"
                      >
                        <b-form-input
                          id="input-username"
                          v-model="user.username"
                          :state="getValidationState(validationContext)"
                          placeholder="Enter username"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Password:" label-for="input-password">
                      <validation-provider
                        name="Password"
                        rules="required"
                        v-slot="validationContext"
                      >
                        <b-form-input
                          id="input-password"
                          type="password"
                          v-model="user.password"
                          :state="getValidationState(validationContext)"
                          placeholder="Enter password"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <b-col>
                    <b-button type="submit" variant="primary">Login</b-button>
                    <b-button
                      class="ml-3"
                      @click="$router.push(`/account/reset-password`)"
                      >Reset Password</b-button
                    >
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
import AdminHeader from "@/components/admin/Header.vue";
import User from "../../models/user";

export default {
  name: "Login",
  components: {
    AdminHeader
  },
  data() {
    return {
      user: new User("", ""),
      loading: false,
      message: ""
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters["auth/isAuthenticated"];
    }
  },
  created() {
    if (this.loggedIn) {
      let userId = this.$store.state.auth.user.id;
      this.$router.push(`/admin/${userId}/dashboard`);
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onLogin() {
      this.loading = true;
      this.isSubmitting = true;
      if (this.user.username && this.user.password) {
        this.$store.dispatch("auth/login", this.user).then(
          () => {
            let userId = this.$store.state.auth.user.id;
            if (this.$store.state.auth.user.roles.includes("Admin")) {
              this.$router.push(`/admin/${userId}/manage-users`);
            } else {
              this.$router.push(`/admin/${userId}/dashboard`);
            }
          },
          error => {
            this.loading = false;
            this.message = error;
          }
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";
</style>
